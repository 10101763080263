const Hero = () => {
  return (
    <section className='hero' id='home'>
      <div className='hero-banner'>
        <h1>LEARNING TILL OBLIVION</h1>
        <p>
         ROHITH CHATRATHI</p>
         <p>
          Techno lunatic during the day cyber punk during the night
        </p>
        <a href='https://www.linkedin.com/in/rohithchatrathi-0a6a0820a/' className='btn hero-btn '>
        HIRE ME
        </a>
      </div>
    </section>
  )
}
export default Hero
